import React from 'react'
import './footer.css'
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

const footer = () => {
    return (
        <>
            <footer className="hotel-footer">
                <div className="container">
                    <div className="row">

                        <div className="col-md-3">
                            <h2>Registered Office</h2>
                            <address>
                                <h6>Friends Hotel</h6>
                                <p>PAUL PLAZA, Bagnan College Road, Vill Khalore</p>
                                <p> P.S Bagnan, Howrah,Westbengal-711303</p>
                                <p>Email: <a href="mailto:booking@friendshotel.in" className='text-hotel' target='_blank'>booking@friendshotel.in</a> </p>
                                <p>Complains & Suggetions : <a href='https://api.whatsapp.com/send/?phone=918777209081' className='text-hotel' target='_blank'>
                                    <FaWhatsapp />{' '}  +91 8777209081</a> </p>
                            </address>
                        </div>
                        <div className="col-md-3">
                            <h2>Contact Us</h2>
                            <address>
                                <h6>Sourav Infotech</h6>
                                <p>Address: <a className='text-hotel' target='_blank' href='https://www.google.com/maps/dir/22.590753,88.362179/Sourav+Infotech,+82D,+Dani+Ghosh+Sarani+Road,+Garanhatta,+Beniatola,+Kolkata,+West+Bengal+700006,+India/@22.5907431,88.3595989,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3a0277cb27892585:0x225e1a9626de21a7!2m2!1d88.3621945!2d22.5908013?entry=ttu'>
                                    82D Beadon Street,Oppsite Minarva Theater,Kolkata, WB 700006
                                </a> </p>
                                <p>Email: <a href="mailto:stay.friendshotel@gmail.com" className='text-hotel' target='_blank'>
                                    Stay.Friendshotel@gmail.com</a>
                                </p>
                                <p>Phone/Whatsapp: <a href='tel:+918777209081' className='text-hotel' target='_blank'>
                                    <FaWhatsapp />{' '} +91 8777209081 (Jayanta Ghosh)</a> </p>
                                    <p>Phone/Whatsapp: <a href='tel:+917003391428' className='text-hotel' target='_blank'>
                                    <FaWhatsapp />{' '} +91 7003391428 (Sourav Ghosh)</a> </p>
                               
                            </address>
                        </div>
                        <div className="col-md-3">
                            <h2>Payment Accounts</h2>
                            <address>
                                <p> Name: Friends Hotel<br />Account Number:032263700000897<br />IFSC Code:YESB0000322</p>
                                {/* <p> Name: Friends Hotel<br />Account Number:032263700000877<br />IFSC Code:YESB0000322</p> */}

                                <p>Upi Address: 8274899804@okbizaxis</p>
                                <p>Upi Address: 8981330127@okbizaxis</p>
                                <p className='text-warning'>To prevent potential scams, kindly ensure that payments are made exclusively to the specified account details provided in our Website.</p>
                                <p className='text-danger'>Friendshotel.in shall not be held liable for payments made without specifying account details.</p>

                            </address>
                        </div>

                        <div className="col-md-3">
                            <h2>Our Hotels</h2>
                            <ul>
                                <li>
                                    <a href='/Hotel/Norbulingka-Retreat' target='_blank' className='text-hotel'>Norbulingka Retreat</a> at Rinchenpong,Sikkim

                                </li>
                               
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h2>Social</h2>
                            <div className='d-flex justify-content-around'>
                                <li>
                                    <a href='https://www.instagram.com/friendshotel.in/' target='_blank' className='text-hotel'>
                                        <FaInstagram />{' '}Instagram
                                    </a>

                                </li>
                                <li>
                                    <a href='https://www.youtube.com/@Friendshotel_dot_in' className='text-hotel' target='_blank'>
                                        <FaYoutube />{' '}Youtube
                                    </a>
                                </li>
                                <li>
                                    <a href='https://www.facebook.com/Friendshotelgroup' className='text-hotel' target='_blank'>
                                        <FaFacebook /> {' '} Facebook
                                    </a>
                                </li>

                            </div>
                        </div>

                    </div>
                </div>
                <footer className='text-center mt-2'>
                    <h5 className='text-light'>
                        Develpoed by{'  '}
                        <a className='text-hotel' href='https://in.linkedin.com/in/ghsourav?trk=public-profile-badge-profile-badge-view-profile-cta/' target='_blank'><FaLinkedin /> Ghsourav</a>
                    </h5>
                </footer>
            </footer>
        </>
    )
}

export default footer