import React from 'react';
import './ResponsiveEmbed.css'; // Make sure to import the CSS
import { isMobile } from 'react-device-detect';

const FacebookVideoEmbed = ({ src }) => {
  return (
    <div className="responsive-iframe">
      <iframe
        src={src}
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
};

// const FacebookPostEmbed = ({ src }) => {
//   return (
//     <div className="responsive-iframe">
//       <iframe
//         src={src}
//         style={{ border: 'none', overflow: 'hidden' }}
//         scrolling="no"
//         frameBorder="0"
//         allowFullScreen={true}
//         allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//       ></iframe>
//     </div>
//   );
// };

const FApp = () => {
  return (
    <div className="container mb-4">

      <div className="row">
        <div className="col col-lg-4 col-sm-12 mt-2">
          <FacebookVideoEmbed
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FFriendshotelgroup%2Fvideos%2F986394423278921%2F&show_text=true&width=560&t=0"
          />
        </div>
        <div className="col col-lg-4 col-sm-12 mt-2">
          <FacebookVideoEmbed src="https://www.facebook.com/plugins/video.php?height=322&href=https%3A%2F%2Fwww.facebook.com%2FFriendshotelgroup%2Fvideos%2F348529458302496%2F&show_text=false&width=560&t=0" />
        </div>

       
        
      </div>
    </div>
  );
};

export default FApp;
