import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Logo from '../../../logo/L.jpg'
import Navbar from '../../Navbar';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
} from '@material-ui/core';
import blogData from '../data/blog.json'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from '@mantine/core';
const useStyles = makeStyles((theme) => ({
    appBar: {
        marginBottom: theme.spacing(4),
    },
    card: {
        maxWidth: 345,
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    media: {
        height: 200,
    },
}));



function App() {
    const classes = useStyles();
    const seodataObj = {
        h1: 'Hotel Booking in Gangtok and Rinchenpong',
        p: 'Book your hotel in Gangtok and Rinchenpong with the best rates and excellent amenities.',
        heroDescription: 'Find the perfect hotel for your stay in Gangtok and Rinchenpong.',
        url: 'http://friendshotel.in/'
    };
    const [language, setLanguage] = useState("EN")
    const abc = "https://ik.imagekit.io/ghsourav/FriendsHotel/hero/237055550.jpg_k=8e4ead78bf905fff447d7f4b84327f27b43dfaa2ef7e5d4176493fc1e13e7adc&o=?updatedAt=1691304187309"
    return (
        <>
            {/* <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6">Beautiful Blog</Typography>
                </Toolbar>
            </AppBar> */}

            <Helmet>
                <title>Friends Hotel - Blog</title>
                <meta name="description" content={seodataObj.p} />
                <meta property="og:title" content={seodataObj.h1} />
                <meta property="og:description" content={seodataObj.heroDescription} />
                <meta property="og:image" content={abc} />
                <meta property="og:url" content={seodataObj.url} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Friends Hotel" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:card" content={abc} />
                <meta name="twitter:title" content={seodataObj.h1} />
                <meta name="twitter:description" content={seodataObj.heroDescription} />
                <meta name="twitter:image" content={abc} />

                <meta name="keywords" content="Hotel Booking, Gangtok, Rinchenpong, Hotel, Accommodation, Travel" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Ghsourav" />
                <h1>{seodataObj.h1}</h1>
                <h3>{seodataObj.heroDescription}</h3>
                <p>{seodataObj.p}</p>
                <meta
                    name="description"
                    content="Welcome to Friends Hotel - Feel the views of Himalaya."
                />
                <Navbar />

                <div className="container">
                    <div className="row">
                        {blogData.articles.map((blog) => (
                            <div key={blog.id} className="col-12 col-sm-6 col-md-4">
                                <Link to={"Blog/" + language + "/" + blog.url}>
                                    <div className="card">
                                        <img className="card-img-top" src={Logo} alt={blog.title} />
                                        <div className="card-body">
                                            <h1 className="card-title">{blog.title}</h1>
                                            <h2 className="card-text">{blog.description}</h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

            </Helmet>
            <Navbar />

            <Container>

                <Grid container spacing={2}>
                    {blogData.articles.map((blog) => (
                        <Grid item key={blog.id} xs={12} sm={6} md={4}>
                            <Link to={"Blog/" + language + "/" + blog.url}>
                                <Card className={classes.card}>
                                    <CardActionArea>
                                        <CardMedia
                                            className={classes.media}
                                            image={Logo}
                                            title={blog.title}
                                        />
                                        <CardContent>
                                            <Typography variant="h6" component="h2">
                                                {blog.title}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {blog.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Link>

                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
}

export default App;
