import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx'
import { useParams, useLocation, Link, useHistory } from 'react-router-dom'
import Navbar from '../Navbar';
import { Card, Image, Text, Badge, Group, Skeleton, Tooltip, Accordion } from '@mantine/core';
import { BiSolidPhoneCall } from 'react-icons/bi'
import { MdEmail, MdCall } from 'react-icons/md'
import { SlCalender } from 'react-icons/sl'
import { FaHotel } from 'react-icons/fa'
import { isMobile } from 'react-device-detect';
import useLazyLoad from '../../common/useLazyLoad';
import ProgressBar from '../../common/Progressbar';
import Am from '../Home/ActivitiesAndServices'
import Logo from '../../logo/logo.png'
import { Helmet } from 'react-helmet';
import './HotelPageIndex.css'
import json from './hotelpageData.json'


import FApp from '../Home/FacebookFrames/Index'

const HotelPageIndex = () => {
    const { name } = useParams();
    const history = useHistory()


    const [hotelData, setHotelData] = useState();
    const [TOTAL_PAGES, setTOTAL_PAGES] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const hotelDetails = json
    function findArticleByUrl(url) {
        console.log(url);

        const details = hotelDetails.find(article => article.slug.toLocaleUpperCase() === url.toLocaleUpperCase());
        return details ? details : null
            ;
    }

    const faq = [
        {
            emoji: '🚗',
            value: 'Is Car parking available for free?',
            description: 'Yes, we offer complimentary parking for our guests.'
        },
        {
            emoji: '🛌',
            value: 'What can I expect from your rooms?',
            description: 'Our rooms are cozy and comfortable, providing a relaxing stay for our guests.All Room has view of Mount Kanchenjunga'
        },
        {
            emoji: '🍽️',
            value: 'Tell me about your dining options.',
            description: 'We offer delicious dining experiences with a variety of menu options to satisfy your culinary preferences.'
        },
        {
            emoji: '🍹',
            value: 'Do you provide welcome drinks?',
            description: 'Yes, we welcome our guests with refreshing drinks upon arrival.'
        },
        {
            emoji: '📶',
            value: 'Is there free Wi-Fi available?',
            description: 'Absolutely! We provide complimentary Wi-Fi for our guests to stay connected.Password is FriendsHotel.In'
        },
        {
            emoji: '📷',
            value: 'How is the security at your hotel?',
            description: 'We prioritize your safety with 24/7 CCTV security surveillance.'
        },
        {
            emoji: '🔌',
            value: 'What about power backup?',
            description: 'Rest assured, we have 24/7 power backup to ensure a seamless stay for our guests.'
        },
        {
            emoji: '👨‍👩‍👧‍👦',
            value: 'Is your hotel family-friendly and couple-friendly?',
            description: 'Yes, we are welcoming to both families and couples, ensuring a comfortable stay for all.'
        },
        {
            emoji: '🛎️',
            value: 'What are the hours for room service?',
            description: 'Our room service is available from 7 am to 11 pm for your convenience.'
        },
        {
            emoji: '🖥️',
            value: 'What are the hours for Reception Desk?',
            description: 'Our  Reception Desk is available from 7 am to 11 pm for your convenience.'

        }
    ];

    const items = faq.map((item) => (
        <Accordion.Item key={item.value} value={item.value}>
            <Accordion.Control icon={item.emoji}>{item.value}</Accordion.Control>
            <Accordion.Panel>{item.description}</Accordion.Panel>
        </Accordion.Item>
    ));

    const location = useLocation()

    useEffect(() => {
        console.log(location);
        let l="";
        if(location.pathname=="/"){
            l="Norbulingka-Retreat"
        }else{
            l=location.pathname.replace("/","")
            l=l.replace("Hotel/","")
           
            
        }
        console.log(l);
        
        const fetch = findArticleByUrl(l);

        setHotelData(fetch)
        // console.log(fetch.Album.length)
        setTOTAL_PAGES(fetch.Album.length)
    }, [name]);



    function getRandomImage(images) {
        // Generate a random index between 0 and the length of the images array
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
    }


    //For Lazy Loading
    const NUM_PER_PAGE = 6
    const triggerRef = useRef(null);
    const onGrabData = (currentPage) => {
        return new Promise((resolve) => {
            setTimeout(() => {

                const data = hotelData.Album.slice(
                    ((currentPage - 1) % TOTAL_PAGES) * NUM_PER_PAGE,
                    NUM_PER_PAGE * (currentPage % TOTAL_PAGES)
                );
                resolve(data);
            }, 1);
        });
    };

    const { data, loading } = useLazyLoad({ triggerRef, onGrabData });
    if (hotelData != null) {
        if (data.length != 0) {
            if ((data.length / hotelData.Album.length) * 100 > 100) {
                window.location.reload();
            }
        }
    }



    return (
        <>
            <Navbar />

            {hotelData == null ? <>
                <img src={Logo} className='mt-4 text-center' />
                <h1 className='mt-4 text-center'>
                    No Data Found</h1>
            </> :
                <>
                    <Helmet>
                        <title> {hotelData.title}</title>
                        <meta name="description" content={'Best value for money hotels is ' + hotelData.welcome + ' operated by Friendshotel.in, in Sikkim , India'} />
                        <meta property="og:title" content={'Friends Hotel-' + hotelData.welcome} />
                        <meta property="og:description" content={'Best value for money hotels in Sikkim ,Friends Hotel-' + hotelData.welcome} />
                        <meta property="og:image" content={hotelData.heroimg} />
                        {/* <meta property="og:url" content={dataObj.url} /> */}
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content={'Friends Hotel-' + hotelData.welcome} />
                        <meta property="og:locale" content="en_US" />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content={hotelData.welcome} />
                        <meta name="twitter:description" content={hotelData.welcome} />
                        <meta name="twitter:image" content={hotelData.heroimg} />
                        <meta name="keywords" content="Best value for money hotels in Gangtok,Hotel Booking, Gangtok, Pelling, Hotel, Accommodation, Travel" />
                        <meta name="robots" content="index, follow" />
                        <meta name="author" content="Ghsourav" />
                        <section className='container mt-4'>
                            <h2 className='mt-2 text-center'>FAQ</h2>
                            <Accordion defaultValue="Is Car parking available for free">
                                {items}
                            </Accordion>
                        </section>

                    </Helmet>
                    <div className={'Hotelhero bg-my p-3'}>
                        <main className='container'>
                            <main className='row'>

                                <div className='col-lg-8 col-sm-12'>
                                    <img src={hotelData.heroimg} className='heroimg shadow bg-loader' />
                                </div>
                                <div className='col-lg-4 col-sm-12 '>

                                    <div className={isMobile ? 'bg-myopacity shadow p-3' : 'mt-4rem bg-myopacity shadow p-3'}>
                                        <h1 class="mt-2 text-center text-hotel ">
                                            Welcome to
                                        </h1>
                                        <h4 class="mt-2 text-center">
                                            {hotelData.welcome}
                                        </h4>
                                        <h3 className='text-center'>
                                            {hotelData.bookingContact ? <>
                                                <Tooltip label={hotelData.bookingContact}>
                                                    <a className='a-text-decoration-none' target='_blank' href={'tel://' + hotelData.bookingContact}><MdCall />{" "}Call</a>
                                                </Tooltip>
                                            </>
                                                : null}
                                            {hotelData.BookingLink ? <a className='a-text-decoration-none' target='_blank' href={hotelData.BookingLink}><SlCalender />{" "}Book Now</a> : null}
                                        </h3>
                                    </div>
                                </div>
                            </main>
                        </main>
                    </div>
                    <div>
                        <h1 className='mt-2 text-center text-hotel '>Welcome</h1>
                        <h4 className='mt-2 text-center'>{hotelData.welcome}</h4>
                        <p className='container text-center p-4'>
                            {hotelData.sub_welcome_note}
                        </p>
                        <section>
                        </section>
                    </div>

                    <FApp/>

                    {/* <div className='container'>
                        <h2 className='mt-2 text-center text-hotel '>Youtube Videos</h2>
                        <iframe className='p-4' width="620" height="360" src="https://www.youtube.com/embed/JrhbUNp0IIg?si=j2w18Qc05ah5S-tF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        
                        <iframe className='p-4' width="620" height="360" src="https://www.youtube.com/embed/07uXYojigqU?si=XC_p2FRsPGiTnohl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                        <h2 className='mt-2 text-center text-hotel '>Facebook Videos</h2>
                        <iframe className='p-4' width="620" height="360" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FFriendshotelgroup%2Fvideos%2F1040839290763808%2F&show_text=true&width=560&t=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      
                        <iframe className='p-4' width="620" height="360" src="https://www.facebook.com/plugins/video.php?height=323&href=https%3A%2F%2Fwww.facebook.com%2FFriendshotelgroup%2Fvideos%2F395854859901572%2F&show_text=true&width=560&t=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


                    </div> */}

                    <div className='bg-warning p-4'>
                        <h4 className='text-center text-white'>
                            Hello Guest & Bussiness Partner!<br />
                            To avoid any potential scams, kindly make an advance payment for your stay through our Account or UPI ID. You can find the account details at the end of our website. <br />Thanks!
                        </h4>
                    </div>



                    <div className='container'>
                        <div className='row'>
                            {hotelData.rooms.map((d) =>
                                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                                        <Card.Section>
                                            <Image
                                                className='bg-loader'
                                                src={d.img.length == 0 ? Logo : getRandomImage(d.img)}

                                                alt={d.name}
                                            />
                                        </Card.Section>
                                        <Group position="apart" mt="md" mb="xs">
                                            <Text weight={500}>{d.name}</Text>
                                            <Badge color="green" variant="light">
                                                {d.offercode}
                                            </Badge>
                                        </Group>
                                        <Tooltip label={d.offercode}>
                                            <Text size="sm" color="dimmed">
                                                {d.ofernote}
                                            </Text>
                                        </Tooltip>


                                        {/* <Link className="btn btn-info" to={d.url}></Link> */}
                                        {hotelData.BookingLink ? <a className="btn btn-primary mt-4" target='_blank' href={hotelData.BookingLink}>Book</a> : null}
                                    </Card>



                                </div>)}


                        </div>
                    </div>

                    {/* What We Offer */}
                    <section className='mt-4  bg-light'>

                        {/* <h1 className='text-center ghosh-h1'>What We Offer</h1>
                        <p className='text-center m-4'>AMENITIES</p>
                        <p className='text-center m-2' >Stay with us in creating your one-of-a-kind holiday experience together.</p> */}
                        <div>
                            {/* <main className=' aminity-div'>
                                {hotelData.aminity.map((d) =>
                                    <>
                                        <div className='service p-5 my-md-3 my-lg-3 my-sm-0 shadow-lg '>
                                            <span>{d.icon}</span>
                                            {d.iconname}
                                        </div>
                                    </>)}

                            </main> */}

                            <Am />
                        </div>




                    </section>


                    <>
                        <main className='container'>
                            <h1 className='text-center text-hotel Ysabeau m-4'>
                                Album
                            </h1>
                            <div className='row'>
                                {data.map((d => <>
                                    <div className='col-lg-4 col-md-4 col-sm-4 mt-4'>
                                        <img className='pho bg-loader img-thumbnail' src={d} />
                                    </div>
                                </>))}
                                <>
                                    {/* {NUM_PER_PAGE === hotelData.Album.length ? null : <p className='btn btn-primary text-center' onClick={() => setShowMore(true)}>Show More..</p>} */}


                                    {data.length === hotelData.Album.length ? null : <div ref={triggerRef} className={clsx("trigger", { visible: loading })}>
                                        <>

                                            <Skeleton visible={true} ></Skeleton>
                                            <Skeleton height={16} mt={6} mb={6} radius="xl" />
                                            <Skeleton height={16} mt={6} mb={6} radius="xl" />
                                            <Skeleton height={16} mt={6} mb={6} radius="xl" />
                                            <Skeleton height={16} mt={6} mb={6} radius="xl" />
                                            <Skeleton height={16} mt={6} mb={6} radius="xl" />
                                            <Skeleton height={16} mt={6} mb={6} radius="xl" />
                                        </>
                                        <ProgressBar current={data.length} total={hotelData.Album.length} />
                                    </div>}
                                </>


                            </div>
                        </main>
                        <section className='container mt-4'>
                            <h2 className='mt-2  text-hotel Ysabeau text-center'>FAQ</h2>
                            <Accordion defaultValue="Is Car parking available for free">
                                {items}
                            </Accordion>
                        </section>
                    </>

                    <section>
                        <h1 className='text-center text-hotel Ysabeau m-4'>
                            Sightseeing
                        </h1>
                        <main className='container'>
                            <div className='row'>

                                {hotelData.sightseeing.map((d) =>

                                    <div className='col-lg-3 col-md-4 col-sm-12 mt-4'>
                                        <Link to={d.blogurl}>
                                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                                <Card.Section>
                                                    <Image
                                                        className='bg-loader'
                                                        src={d.img == null ? Logo : d.img}
                                                        height={200}
                                                        alt={d.name}
                                                    />
                                                </Card.Section>
                                                <Group position="apart" mt="md" mb="xs">
                                                    <Text weight={500}>{d.name}</Text>
                                                    <Badge color="pink" variant="light">
                                                        {d.offercode}
                                                    </Badge>
                                                </Group>
                                                <Text size="sm" color="dimmed">
                                                    Read More...
                                                </Text>
                                            </Card>
                                        </Link>
                                    </div>)}


                            </div>
                        </main>

                    </section>



                    <main className='p-3'>
                        <section className='row mt-4' >
                            <div className='col-lg-6 col-md-6 col-sm-12 mymapcard' >
                                <>
                                    <iframe
                                        title={hotelData.maptitle}
                                        src={hotelData.mapiframeUrl}
                                        width="100%"
                                        height="100%"
                                        style={{ border: 0 }}
                                        allowFullScreen></iframe>
                                </>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 bg-light mymapcard'>
                                <div className='text-center m-4'>
                                    <h4>{hotelData.welcome}</h4>
                                    <h5><FaHotel className='text-hotel' />{"  "}Hotel Address</h5>
                                    <p>{hotelData.address}</p>
                                    <h5> <MdEmail className='text-hotel' /> Central Reservation</h5>
                                    <p>{hotelData.email}</p>
                                    <h5><BiSolidPhoneCall className='text-hotel' />  Booking Contact</h5>
                                    <p>{hotelData.bookingContact}</p>
                                    <h5> <BiSolidPhoneCall className='text-hotel' /> Hotel Contact</h5>
                                    <p>{hotelData.contact}</p>
                                </div>
                            </div>
                        </section>
                    </main>

                </>}

        </>
    )
}

export default HotelPageIndex